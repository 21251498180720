import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import getAssetUrl from '../lib/CDNAsset.js';

import CentralLink from '../components/CentralLink.js';

import ProminentLinkCard from '../components/ProminentLinkCard.js';

import { getHomePageView } from '../store/reducers/fmAPI.js';

import FloodAlertHeroSummary from '../components/FloodAlertHeroSummary.js';

const FloodNav2 = getAssetUrl('FloodNav2-h140.webp');
const DataExplorer = getAssetUrl('DataExplorer-h140.webp');



const FloodAlertIcon = getAssetUrl('flood-alert-icon-960-h140.webp');

function HomePage(props) {
	const dispatch = useDispatch();
	const { viewHome, errorList } = useSelector(state => state.fmAPI);

	// const dispatch = useDispatch();
	// const { basins, fmCoverageAreas } = useSelector(state => state.fmAPI);

	// useEffect(() => {
		// dispatch(getBasins());
		// dispatch(getFloodMapperCoverageAreas());
	// }, [dispatch]);

	let callerTrace = "homePageView";
	useEffect(() => {
		dispatch(getHomePageView(callerTrace));
	}, [dispatch, callerTrace])

	let topFloodAlertBanner = null;
	topFloodAlertBanner = <FloodAlertHeroSummary viewHome={viewHome} />
	if(errorList && errorList[callerTrace]) {
		topFloodAlertBanner = <Row className="align-items-center">
			<Col xs={4} className="d-flex justify-content-center align-items-center p-3">
				<CentralLink to={"/flood-alerts"}>
					<Card.Img src={FloodAlertIcon} style={{ maxWidth: "95%", maxHeight: "140px", objectFit: "contain", "cursor": "pointer" }} alt="The icon here denotes a flood alert, this component normally reflects the highest alert level set through the country at this time, but there was an error retrieving or processing the data and this icon is our fallback position. To view the current state navigate via this component to the flood alert summary page." />
				</CentralLink>
			</Col>
			<Col xs={8}>
				<i>Error loading alert summary</i>
				<h3>
					<CentralLink to={"/flood-alerts"}>
						<Button variant="dark" size="sm">View UK Warnings</Button>
					</CentralLink>
				</h3>
			</Col>
		</Row>;
	}


	return (
	<div>
		<Helmet>
			<title>FloodMapper.co.uk - Stay Ahead of the Waters</title>
		</Helmet>
		<Row className="align-items-center">
			<Col xs={12} className="text-white bg-dark"><h1 className="h1-welcome-to-floodmapper">Welcome to FloodMapperUK</h1></Col>
		</Row>
		{topFloodAlertBanner}
		<Row>
			<Col className="text-white bg-dark">&nbsp;</Col>
		</Row>
	  	<div style={{ paddingTop: "2%" }}>
		    <Row>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    	image={DataExplorer}
			    	imageAlt="The explorer logo which can be clicked to view a geographical map of UK Stations."
			    	title="Find a Station"
			    	body="Find a measurement station in the UK using a map."
			    	buttonText="View Station Map"
			    	navigationTarget="/data-explorer/stationmap/uk"
		    	/>
			    </Col>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    		image={FloodNav2}
			    		imageAlt="The FloodNav logo which can be clicked to search for a local FloodNav. FloodNav is a live flood map service provided by FloodMapperUK." 
			    		title="Find a FloodNav"
			    		body="FloodNav is an innovative, real-time mapping tool that provides users with current flood data and safe route navigation being trialed by us in flood-prone areas of the UK."
			    		buttonText="View FloodNavs"
			    		navigationTarget="/floodnav"
			    	/>
			    </Col>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    	image={DataExplorer}
			    	imageAlt="The Data Explorer logo which can be clicked to browse various data such as: monitoring stations, rivers, basins and more)."
			    	title="Data Explorer"
			    	body="Explore and analyse environmental data on our platform, including river basins, catchment areas, flood alerts, defences, and real-time river height measurements."
			    	buttonText="Explore"
			    	navigationTarget="/data-explorer"
		    	/>
			    </Col>
			</Row>
	  	</div>
	 </div>
);




}


export default HomePage




	// return <div style={{marginLeft: "2%", marginRight: "2%"}}>
	// 	{<Row>
	// 		<Col xs={0} md={1}>&nbsp;</Col>
	// 		<Col xs={3} md={2}>&nbsp;</Col>
	// 		<Col xs={9} md={8}>
	// 			<b>FloodMapper.co.uk</b><br />
	// 			Real-time flood and river data at your fingertips. Stay updated with the latest river heights, flood warnings, and safety tips across the UK. Essential information for residents and travelers alike.
	// 		</Col>
	// 		<Col xs={0} md={1}>&nbsp;</Col>
	// 	</Row>}
	// 	<Row className="mx-auto justify-content-md-center">
	// 		<Col xs={12} className="mx-auto justify-content-md-center">
	// 			<FMCoverageAreas fmCoverageAreas={fmCoverageAreas} />
	// 		</Col>
	// 	</Row>
	// 	<Row>
	// 		<Col xs={12} md={6} lg={3}>
	//           <RiverBasins basins={basins} />
	//         </Col>
	// 	</Row>
	// </div>;